.header {
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    z-index: 10;
}

.header__inner {
    display: flex;
    align-items: center;
}

.header__nav {
    margin-left: 60px;
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.header__menu {
    display: flex;
    align-items: center;
    gap: 0 12px;
    flex-grow: 1;
}

.header__list {
    display: flex;
    gap: 0 32px;
    align-items: center;
}

.header__list--second {
    margin-left: auto;
}

.header__link {
    color: var(--white);
    font-size: 14px;
    font-weight: 700;
}

.header__wrapper {
    margin-left: 12px;
    display: flex;
    gap: 0 12px;
}

.header__button-icon {
    fill: #ab6400;
    border: 1px solid #fbe577;
    border-radius: 8px;
    background-color: #ffee9c;
    padding: 8px 15.5px;
    display: flex;
    align-items: center;
    gap: 0 4px;
    color: #AB6400;
    font-weight: 500;
    font-size: 16px;
}

.header__button-icon svg {
    flex-shrink: 0;
}

.header__sign,
.header__basket {
    flex-shrink: 0;
    height: 40px;
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9999px;
    color: var(--black);
    font-size: 14px;
    font-weight: 500;
}

.header__sign {
    width: 72px;
}

.header__sign svg {
    display: none;
}

.header__basket {
    width: 40px;
    position: relative;
    stroke: var(--black);
}

.header__quantity {
    min-width: 16px;
    min-height: 16px;
    border-radius: 9999px;
    font-weight: 600;
    font-family: 'SF', sans-serif;
    font-size: 8px;
    color: var(--white);
    background-color: #dc3e42;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: -6px;
    right: -3px;
}

.header__menu-button {
    display: none;
}

.header__logo {
    fill: var(--white);
    transition: .2s all ease-in;
 
}

.header__item svg {
    display: none;
}

@media (max-width: 1200px) {
    .header__nav {
        margin-left: 20px;
    }

    .header__list {
        gap: 0 20px;
    }

    .header__link,
    .header__sign {
        font-size: 12px;
    }

    .header__sign {
        width: 60px;
    }

    .header__button-icon {
        font-size: 14px;
    }
}

@media (max-width:960px) {
    .header__menu {
        position: absolute;
        left: 0;
        right: 0;
        top: -30px;
        z-index: 5;
        flex-direction: column;
        align-items: flex-start;
        padding: 87px 20px 40px;
        background-color: var(--white);
        border-radius: 0 0 20px 20px;
        text-align: start;
        transition: .5s all ease-in;
        opacity: 0;
        pointer-events: none;
    }

    .header__link {
        color: var(--black);
        font-size: 18px;

    }

    .header__list--second .header__link {
        font-size: 16px;
    }

    .header__list {
        flex-direction: column;
        align-items: flex-start;
        margin: 0;
        gap: 22px 0;
    }

    .header__list:not(.header__list--second) {
        order: 1;
        padding-top: 30px;
        border-top: 1px solid #f0f0f3;
        margin-top: 24px;
        width: 100%;
    }

    .header__menu-button {
        display: block;
        fill: var(--white);
        margin-right: 9.5px;
        transition: .4s all ease-in;
    }

    .header--active .header__logo {
        transition-delay: .5s;
    }


    .header--active .header__menu-button {
        fill: var(--black);
        transform: rotate(90deg);
    }

    .header--active .header__menu {
        z-index: -1;
        opacity: 1;
        pointer-events: painted;
    }

    .header--active .header__logo {
        fill: var(--black);
    }

    .header__button-icon {
        font-size: 16px;
        margin-top: 24px;
    }

    .header__wrapper {
        margin-left: auto;
    }

    .header__basket {
        order: -1;
    }

    .header__sign {
        width: 40px;
    }

    .header--active .header__sign {
        border: 1px solid #D9D9E0;
    }

    .header__sign span {
        display: none;
    }

    .header__sign svg,
    .header__item svg {
        display: block;
    }

    .header__item {
        stroke: var(--black);
        display: flex;
        gap: 0 8px;
        align-items: center;
    }


}