.footer {
    background-color: var(--black);
    padding: 40px 0 31px;
}

.footer__logo {
    fill: var(--white);
}

.footer__content {
    display: flex;
    justify-content: space-between;
    gap: 0 20px;
}

.footer__wrapper {
    display: flex;
    gap: 0 82px;

}

.footer__block-title {
    color: var(--white);
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 12px;
}

.footer__list {
    display: flex;
    flex-direction: column;
    gap: 8px 0;
}

.footer__link {
    font-size: 12px;
    color: var(--white);
    font-weight: 500;
}

.footer__button {
    color: #ffca16;
    fill: #ffca16;
    font-size: 12px;
    display: flex;
    font-weight: 500;
    gap: 0 6.13px;
    align-items: center;
    border: 1px solid #4d3000;
    border-radius: 6px;
    background-color: #3f2700;
    margin-top: 12px;
    padding: 8px 13px;
}

.footer__bottom {
    padding-top: 24px;
    margin-top: 64px;
    border-top: 1px solid #ffffff1a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: #d9d9e0;
}

.footer__socials {
    display: flex;
    gap: 0 9.6px;
}




@media (max-width:1000px) {

    .footer__content {
        flex-direction: column;
        gap: 20px 0;
    }

    .footer__wrapper {
        justify-content: space-between;
        gap: 20px 30px;
        flex-wrap: wrap;
    }
}

@media (max-width:480px) {
    .footer {
        padding: 20px 0;
    }

    .footer__wrapper {
        flex-direction: column;
    }
    .footer__bottom {
        border: 0;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 24px;
        padding: 0;
    }

    .footer__socials {
        order: -1;
        width: 100%;
        padding-bottom: 24px;
        border-bottom: 1px solid #ffffff1a;
    }

    .footer__social-link svg {
        width: 48px;
        height: 48px;
    }
    .footer__rights {
        margin-top: 24px;
    }
    
}