.button {
    border-radius: 8px;
    display: flex;
    gap: 0 4px;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 18px;
    border-radius: 8px;
    color: var(--white);
    padding: 12px 24px;
    background-color: #1f2d5c;
    transition: .4s all ease-in;
}


.button:hover {
    background-color: #0f2b84;
    
}