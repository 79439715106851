.line {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: -1;
}

.line__inner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.line__item {
    width: 1px;
    background-color: #E4EBFE;
}

.line__middle-item {
    width: 1px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(50% + 20px);
    background-color: #E4EBFE;
    z-index: -1;
}

@media (max-width:1400px) {
    .line__inner {
        padding: 0;
    }
}

@media (max-width:1200px) {
    .line__middle-item {
        left:50%;
    }
}