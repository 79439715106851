@import url(./reset.css);
@import url(./fonts.css);


:root {
    --white: #fff;
    --page:#F9F9F9;
    --black: #1c2024;
    --blue:#1f2d5c;
    --gray: #60646c;
}

body {
    font-family: 'Roboto', sans-serif;
    background-color: var(--page);
}

.container-b {
    max-width: 1400px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
}

.container {
    max-width: 1244px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
}


.main {
    position: relative;
    overflow: hidden;
}